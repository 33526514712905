import React from 'react';

import {
  StyledTipsContainer
} from './Styles';

export const Tips = ({ returnUrl }) => {
  return (
    <StyledTipsContainer>
      <p>
        <i className="fas fa-info-circle"></i>
        You will need to send them a message first to arrange to start video chat. <a href="https://help.christianconnection.com/help/video-chat">Learn more</a>
      </p>

      <a className="button" href={returnUrl || 'https://www.christianconnection.com/mailbox'}>
         Go Back to Messages
      </a>

      <p>
        Video Chat is a new experimental feature supported on modern iOS and Android devices, and the latest version of Google Chrome
      </p>
    </StyledTipsContainer>
  )
}


