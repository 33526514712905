import React, { useEffect } from 'react';

import {
  Video
} from '@andyet/simplewebrtc';

import {
  StyledRemoteVideoContainer
} from './Styles';

import {
  Event
} from './Tracking'

import {
  ReportLink
} from './ReportLink'

export const RemoteVideo = ({ media }) => {
  useEffect(() => {
    Event({
      category: 'VideoChat',
      action: 'VideoChatConnected',
      label: 'Successful video chat connection'
    });
  }, []);

  return (
    <StyledRemoteVideoContainer>
      <div aria-label="Their live video">
        <Video media={media} />
      </div>
    </StyledRemoteVideoContainer>
  )
}
