import ReactGA from 'react-ga';

export const InitializeGoogleAnalytics = (trackingId) => {
  ReactGA.initialize(trackingId, { location: Location() });
}

export const Event = (props) => {
  ReactGA.event(props);
}

export const PageView = () => {
  ReactGA.pageview(Location());
}

const Location = () => {
  return window.location.pathname + window.location.search
}

