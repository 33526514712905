import React, { useEffect } from 'react';

import { ReportLink } from './ReportLink'
import { RemoteVideo } from './RemoteVideo'
import { Tips } from './Tips'

import {
  StyledRemotePeer,
  StyledRemotePeers,
  StyledRemoteVideoContainer,
} from './Styles'

export const RemotePeers = ({videos, peers, returnUrl}) => {
  if (peers.length === 0) {
    return (
      <div>
        <StyledRemotePeers>
            <StyledRemoteVideoContainer>
              <div>
                <p>Waiting for them to join</p>
              </div>
            </StyledRemoteVideoContainer>
        </StyledRemotePeers>
        <Tips returnUrl={returnUrl} />
      </div>
    )
  } else {
    return (
      <StyledRemotePeers>
        {peers.map(peer => (
          <StyledRemotePeer key={peer.address}>
            <RemoteVideo media={videos.find(video => video.owner == peer.address)} />
            <ReportLink user={peer.customerData} />
          </StyledRemotePeer>
        ))}
      </StyledRemotePeers>
    )
  }
}
