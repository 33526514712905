import { Provider } from "react-redux";
import * as UUID from "uuid";
import React from "react";
import ReactDOM from "react-dom";

import { createStore, Actions, Selectors } from "@andyet/simplewebrtc";

import Cookies from 'universal-cookie';
const cookies = new Cookies();


import App from "./components/App";
import { CenteredContent } from './components/Styles'

// ====================================================================
// IMPORTANT SETUP
// ====================================================================
// Replace `YOUR_API_KEY` here with the API key you received when
// signing up for SimpleWebRTC
// --------------------------------------------------------------------
const API_KEY = 'be37f3f22ab0abe3ef35ee4d';
// ====================================================================

const CONFIG_URL = `https://api.simplewebrtc.com/config/user/${API_KEY}`

const GOOGLE_ANALYTICS_TRACKING_ID='UA-511892-1'

// The provided `createStore` function makes a basic Redux
// store useful for getting things started. If you want to
// make your own, import `reducer` from '@andyet/simplewebrtc' and
// be sure to assign it to `simplewebrtc` in the top level of
// your state object.
const store = createStore();

// We're exposing these here to make it easier for experimenting
// with the actions and selectors in the console.
//
// This is NOT required for SimpleWebRTC to function.
window.store = store;
window.actions = Actions;
window.selectors = Selectors;

const params = new URLSearchParams(window.location.search);
const room = cookies.get('video_room_id');
const token = cookies.get('video_token');
const returnUrl = cookies.get('video_return_url')

// if (!room) {
//   // We're using a UUID for a random room name here, but that is
//   // NOT a requirement for SimpleWebRTC to function.
//   //window.location = `/?room=${UUID.v4()}`;
// }

if (!room || !token) {
  ReactDOM.render((
    <CenteredContent>
      <p>Disconnected. Please go back and restart.</p>
    </CenteredContent>
  ), document.getElementById("app"))
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App
        configUrl={CONFIG_URL}
        googleAnalyticsTrackingId={GOOGLE_ANALYTICS_TRACKING_ID}
        roomName={room}
        roomPassword={params.get('key') || ''}
        userData={token}
        returnUrl={returnUrl}
      />
    </Provider>,
    document.getElementById("app")
  );
}
