import React, { useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

import {
  Provider,
  Connected,
  Connecting,
  Disconnected,
  RemoteAudioPlayer,
  RequestUserMedia,
  Room,
  PeerList
} from '@andyet/simplewebrtc';

import { LocalVideo } from './LocalVideo'
import { AdditionalVideo } from './AdditionalVideo'
import { RemotePeers } from './RemotePeers'

import { InitializeGoogleAnalytics, Event, PageView } from './Tracking'

import {
  StyledUIContainer,
  StyledMainContainer,
  StyledEndCallContainer,
  WaitingAnimation
} from './Styles';

import '../css/WaitingAnimation.scss';

const App = ({ configUrl, userData, returnUrl, roomName, roomPassword, googleAnalyticsTrackingId }) => {
  useEffect(() => {
    InitializeGoogleAnalytics(googleAnalyticsTrackingId);
    PageView();
  }, [googleAnalyticsTrackingId]);

  return (<Provider configUrl={configUrl} userData={userData}>
    {/*
       The <RemoteAudioPlayer/> plays all remote audio, muting as needed
       to reflect if the peer muted themselves, or you've muted them locally.
       See the action `disableMedia(trackId)`.

       It is possible to adjust the volume of each peer (by default it starts at 80%).
       See the action `limitPeerVolume(peerAddress, volumeLimit)`.

       It is also possible to pick the output device used to play sound.
       See the action `setAudioOutputDevice(deviceId)`
    */}
    <RemoteAudioPlayer />

    {/*
      The <Connecting/>, <Connected/>, <Disconnected/> components allow you to create
      UI based on the client connection state. They only render themselves when the
      client is in the corresponding state.
    */}
    <Connecting>
      <WaitingAnimation>
        <span className="waiting-icon"></span>
        <span className="waiting-icon"></span>
        <span className="waiting-icon"></span>
      </WaitingAnimation>
    </Connecting>

    <Disconnected>
      <WaitingAnimation>
        <span className="waiting-icon"></span>
        <span className="waiting-icon"></span>
        <span className="waiting-icon"></span>
      </WaitingAnimation>
    </Disconnected>

    <Connected>
      {/*
        Request user media. In this case we are requesting audio because we include
        the `audio` attribute. Video can be requested by including the `video` attribute.

        The `auto` attribute signals to request media immediately without any UI. If `auto`
        is not provided, a UI element is rendered which can be used to trigger the request.
      */}
      <RequestUserMedia audio video auto />

      {/*
        The <Room/> component triggers joining a room, which can include a media call and chat.

        The `name` property is *not* the identifier used for the room by other components. Instead,
        the room's messaging address is used. That can be found at `room.address`, where `room` is
        one of the properties passed to the child render function. The provided `name` value can later
        be found in `room.providedName`, but be aware that it is an unsanitized value if it can be
        set by a user.

        It is possible to lock rooms, in which case the `password` property must be set in order
        to join. See the actions `lockRoom(roomAddress, password)` and `unlockRoom(roomAddress)`.
      */}
      <Room name={roomName} password={roomPassword}>
        {({ room, peers, localMedia, remoteMedia }) => {
          if (!room.joined) {
            return (
              <WaitingAnimation>
                <span className="waiting-icon"></span>
                <span className="waiting-icon"></span>
                <span className="waiting-icon"></span>
              </WaitingAnimation>
            );
          }

          const remoteVideos = remoteMedia.filter(m => m.kind === 'video');
          const localVideos = localMedia.filter(m => m.kind === 'video' && m.shared);

          return (
            <StyledUIContainer>
              <StyledMainContainer>
                <LocalVideo media={localVideos[0]} />
                <RemotePeers videos={remoteVideos} peers={peers} returnUrl={returnUrl} />
              </StyledMainContainer>
              <StyledEndCallContainer>
                <a className="button" href={returnUrl || 'https://www.christianconnection.com/mailbox'}>
                  <i className="far fa-stop-circle">
                    <span className="sr-only">End Chat</span>
                  </i>
                </a>
              </StyledEndCallContainer>
            </StyledUIContainer>
          );
        }}
      </Room>
    </Connected>
  </Provider>
  );
};

export default App;
