import React from 'react';

import {
  StyledReportContainer
} from './Styles';

export const ReportLink = ({ user: { reportUrl, formattedUsername }}) => {
  if (!reportUrl) {
    return null
  } else {
    return (
      <StyledReportContainer>
        <a href={reportUrl}><i className="far fa-flag"></i>Report{formattedUsername ? ' ' + formattedUsername : ''}</a>
      </StyledReportContainer>
    )
  }
}


