// This demo is using react-emotion for styling, but that is
// NOT a requirement for using SimpleWebRTC

import styled, { css, injectGlobal } from 'react-emotion';

injectGlobal `
  body {
    margin: 0;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  img {
    vertical-align: middle;
    margin: 5px;
    border-radius: 5px;
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    top: auto;
    overflow: hidden;
  }
`;

export const WaitingAnimation = styled('div') `
  @extend .waiting-animation

  position: absolute;
  height: 100%;
  font-size: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const StyledUIContainer = styled('div') `
  position: relative;
  @media (orientation: landscape) {
    max-width: 480px;
  }

  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledMainContainer = styled('div') `
  padding-top: 5%;
`;

export const StyledRemoteVideoContainer = styled('div') `
  width: 90%;
  padding-bottom: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  & div {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #49a4e9;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    & video {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    & p {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 1.2em;
    }
  }
`;

export const StyledLocalVideoContainer = styled('div') `
  position: relative;
  top: 5%;
  left: 5%;
  width: 20%;
  padding-bottom: 20%;

  & div {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #ff5385;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    & video {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

export const StyledEndCallContainer = styled('div') `
  @media (orientation: portrait) {
    position: absolute;
    bottom: 6px;
  }

  @media (orientation: landscape) {
    margin-top: 20%;
  }

  width: 100%;
  text-align: center;

  a.button {
    display: block;
    text-decoration: none;
    color: #777;
    background-color: #EEEEEE;
    border-radius: 4px;
    padding: 2%;
    border: none;

    & i {
      color: #e24000;
    }
  }
`;

export const StyledReportContainer = styled('div') `
  width: 100%;
  text-align: center;
  margin-top: 12px;
  color: #bbb;

  & i {
    text-decoration: none;
    margin-right: 0.2em;
  }

  a, a:hover, a:visited, a:active {
    color: #777;
  }
`;

export const StyledAdditionalVideoContainer = styled('div') `
  position: relative;
  top: 5%;
  left: 5%;
  width: 20%;
  padding-bottom: 20%;

  & div {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #ff5385;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    & video {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

export const CenteredContent = styled('div') `
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRemotePeers = styled('div') `
  & > div:not(:first-child) {
    width: 25%;
    float: left;
  }
`;

export const StyledRemotePeer = styled('div') `

`;

export const StyledTipsContainer = styled('div') `
  text-align: center;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  color: #777;

  & i {
    text-decoration: none;
    margin-right: 0.2em;
  }

  a, a:hover, a:visited, a:active {
    color: #777;
  }

  a.button {
    display: block;
    text-decoration: none;
    color: #777;
    background-color: #EEEEEE;
    border-radius: 4px;
    padding: 2%;
    border: none;
  }
`;

