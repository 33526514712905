import React, { useEffect } from 'react';

import {
  Video
} from '@andyet/simplewebrtc';

import {
  StyledLocalVideoContainer
} from './Styles';

import {
  Event
} from './Tracking'

export const LocalVideo = (props) => {
  if (!props.media) {
    return(
      <StyledLocalVideoContainer>
        <div></div>
      </StyledLocalVideoContainer>
    )
  }

  useEffect(() => {
    Event({
      category: 'VideoChat',
      action: 'VideoChatStarted',
      label: 'Attempted to start a video chat'
    });
  }, []);

  return (
    <StyledLocalVideoContainer>
      <div aria-label="Your camera preview">
        <Video media={props.media} />
      </div>
    </StyledLocalVideoContainer>
  )
}
